import React, { useState} from 'react';
import axios from 'axios';
import { useTranslations } from "../../utils/hooks/useTranslation";
import {useParams} from "react-router-dom";
import './ResetPassword.css'
import qs from "qs";
import {useAlert} from "react-alert";
import PasswordDifficultyBar from "../../components/PasswordDifficultyBar/PasswordDifficultyBar";



function ResetPassword() {

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState();
    const [confirmPasswordError, setConfirmPasswordError] = useState();
    const [matchError, setMatchError] = useState(false);
    const alertBar = useAlert()
    const [success, setSuccess] = useState(false)
    let { token } = useParams();

    const { translate } = useTranslations()


    const validate = () => {
        setPasswordError(false)
        setConfirmPasswordError(false)
        setMatchError(false)

        if (!password) setPasswordError(true)
        if (!confirmPassword) setConfirmPasswordError(true)
        if (password !== confirmPassword) setMatchError(true)
        return  !password || !confirmPassword || password !== confirmPassword;
    }


    const handleSubmit = event => {
        event.preventDefault();
        if (validate()) return;


        const config = {
            data: qs.stringify({'Password': password, 'ConfirmPassword': confirmPassword}),
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            },
            url: `/auth/reset_password/${token}`,
            method: 'post'
        };

        axios(config)
            .then(response => {
                setSuccess(true)
            })
            .catch(error => {
                alertBar.error(error)
                console.warn(error)
            });

    };

    const RenderForm = () => {
        return (
            <div className="ResetPassword_container">

                    <h2 className="ResetPassword_title">{translate("Set new password")}</h2>

                    <form className="Login_form" onSubmit={handleSubmit} noValidate>
                        <div className="aic_input">
                            <label className='aic_input_label text__medium'>{translate('New password')}</label>
                            <input
                                type="password"
                                className="aic_input_input"
                                required
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                id="Password"
                                autoComplete="off"
                            />
                            <div className={`aic_input_error_msg ${passwordError?'show_error':''}`}>{translate("Enter password")}</div>
                            <PasswordDifficultyBar pass={password}/>

                        </div>
                        <div className="aic_input">
                            <label className='aic_input_label text__medium'>{translate('Confirm new password')}</label>
                            <input
                                type="password"
                                className="aic_input_input"
                                required
                                value={confirmPassword}
                                onChange={e => setConfirmPassword(e.target.value)}
                                id="ConfirmPassword"
                                autoComplete="off"
                            />
                            <div className={`aic_input_error_msg ${confirmPasswordError?'show_error':''}`}>{translate("Enter password")}</div>
                            <div className={`aic_input_error_msg ${matchError?'show_error':''}`}>{translate("passwords didn't match")}</div>

                            <PasswordDifficultyBar pass={confirmPassword}/>
                        </div>

                        <button
                            className="btn__hard"
                            type="submit">
                            {translate('Set new password')}
                        </button>
                    </form>
                </div>)
    }


    const RenderSuccess = () => {
        return (
            <div className="ResetPassword_container">
                <h2 className="ResetPassword_title">{`${translate("Success")}`}</h2>
                <div className="Register_success">{translate('Your new password has been set. back to ')} <a href="/">{translate('login page')}</a> {translate('and try to sign in')}</div>
            </div>
        )
    }

    return (
       <div className="ResetPassword page">
           {success && <RenderSuccess/>}
           {!success && <RenderForm/>}

       </div>
   )
}

export default ResetPassword;

