import { useTranslations } from "./hooks/useTranslation";
import Select from "react-select";
import { useState} from "react";
import parse from "html-react-parser";
import PasswordDifficultyBar from "../components/PasswordDifficultyBar/PasswordDifficultyBar";

const selectStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: '0px' }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
}

export const FormField = ({ fields, handleChange, emailError, passError, section_index}) => {
    const [collapseCheckbox, setCollapseCheckbox] = useState(false);

    const { translate } = useTranslations()

    if (!fields) return null;

    return fields?.map((field, index
    ) => {
        switch (field.type) {
            case 'text':
                return <div className="aic_input" key={field.name}>
                    <label
                        className='aic_input_label text__medium'>
                        {translate(field.displayed_name)}
                        {field.optional &&
                        <div className="aic_input_label_optional">({translate("optional")})</div>}
                    </label>
                    <input
                        type="text"
                        className={`aic_input_input ${field.invalid?'invalid':''}`}
                        required={field.is_required}
                        value={field.value || ''}
                        onChange={e => handleChange(e.target.value, index, section_index)}
                        disabled={field.disabled}
                        id={field.name}
                        autoComplete="off"
                    />
                    <div className={`aic_input_error_msg ${field.invalid?'show_error':''}`}>{translate("fill this field")}</div>
                </div>

            case 'email':
                return <div className="aic_input" key={field.name}>
                    <label
                        className='aic_input_label text__medium'>
                        {translate(field.displayed_name)}
                        {field.optional &&
                        <div className="aic_input_label_optional">({translate("optional")})</div>}
                    </label>
                    <input
                        type="text"
                        className={`aic_input_input ${field.invalid?'invalid':''}`}
                        required={field.is_required}
                        value={field.value || ''}
                        onChange={e => handleChange(e.target.value, index, section_index)}
                        disabled={field.disabled}
                        id={field.name}
                        autoComplete="off"
                    />
                    <div className={`aic_input_error_msg aic_input_error_msg_paassword ${field.invalid&&!emailError?'show_error':''}`}>{translate("fill this field")}</div>
                    <div className={`aic_input_error_msg ${emailError?'show_error':''}`}>{translate("wrong email")}</div>

                </div>

            case 'password':
                return <div className="aic_input" key={field.name}>
                    <label
                        className='aic_input_label text__medium'>
                        {translate(field.displayed_name)}
                        {field.optional &&
                        <div className="aic_input_label_optional">({translate("optional")})</div>}
                    </label>
                    <input
                        type="password"
                        className={`aic_input_input ${field.invalid?'invalid':''}`}
                        value={field.value}
                        onChange={e => handleChange(e.target.value, index, section_index)}
                        disabled={field.disabled}
                        id={field.name}
                        autoComplete="off"
                    />
                    <div className={`aic_input_error_msg aic_input_error_msg_paassword ${field.invalid&&!passError?'show_error':''}`}>{translate("fill this field")}</div>
                    <div className={`aic_input_error_msg ${passError?'show_error':''}`}>{translate("passwords didn't match")}</div>

                    <PasswordDifficultyBar pass={field.value}/>
                </div>

            case 'number':
                return <div className="aic_input">
                    <label
                        className='aic_input_label text__medium'>{translate(field.displayed_name)}
                        {field.optional &&
                        <div className="aic_input_label_optional">({translate("optional")})</div>}
                    </label>
                    <input
                        type="number"
                        className={`aic_input_input ${!field.invalid?'invalid':''}`}
                        required={field.is_required}
                        value={field.value || ''}
                        onChange={e => handleChange(e.target.value, index, section_index)}
                        disabled={field.disabled}
                        id={field.name}
                        autoComplete="off"
                    />
                    <div className={`aic_input_error_msg ${field.invalid?'show_error':''}`}>{translate("fill this field")}</div>

                </div>

            case 'select':
                return <div className="aic_input" key={field.name}>
                    <label
                        className='aic_input_label text__medium'>{translate(field.displayed_name)}
                        {field.optional &&
                        <div className="aic_input_label_optional">({translate("optional")})</div>}
                    </label>
                    <Select
                        className={`basic-single ${field.invalid?'invalid':''}`}
                        classNamePrefix="select"
                        name="country"
                        required={field.is_required}
                        styles={selectStyles}
                        options={field.possible_values.map((field) => {
                            return {...field, 'label': translate(field.label)}
                        })}
                        placeholder={translate("-Choose-")}
                        value={field.value}
                        onChange={e => handleChange(e, index, section_index)}
                        isDisabled={field.disabled}
                    />
                    <div className={`aic_input_error_msg ${field.invalid?'show_error':''}`}>{translate("fill this field")}</div>
                </div>

            case 'checkbox':
                return <div className="checkbox__outer_container" key={field.domain+ field.name +  index} >
                    <div className='checkbox__container' onClick={() => handleChange(!field.value, index, section_index)}>
                        <input
                            className={`${field.invalid?'invalid':''}`}
                            checked={field.value}
                            onChange={()=>{}}
                            value={field.value || false}
                            disabled={field.disabled}
                            type="checkbox"/>
                        <p className="checkbox__label">{ parse(field.description || field.name)}</p>
                    </div>
                    <div className={`aic_input_error_msg ${field.invalid?'show_error':''}`}>{translate("fill this field")}</div>

                    { field.long_description &&  <div className="checkbox__show_description_button" onClick={() =>setCollapseCheckbox(!collapseCheckbox)}>
                        {!collapseCheckbox && translate('Show full text')}
                        {collapseCheckbox && translate('Hide full text')}
                    </div>}

                    { field.long_description && collapseCheckbox && <div className="checkbox__long_description">{parse(field.long_description)}</div>}

                </div>

            default:
                return '';
        }
    })
}
