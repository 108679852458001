import React, {useEffect, useState} from 'react';
import axios from 'axios';
import './Profile.css'
import {useNavigate} from 'react-router-dom';
import { useAlert } from 'react-alert'
import Password from "./Tabs/Password";
import Application from "./Tabs/Application";
import ProfileDetails from "./Tabs/ProfileDetails";
import { useTranslations } from "../../utils/hooks/useTranslation";


import { ReactComponent as ApplicationsIcon } from '../../assets/icons/Applications.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/Logout.svg'
import { ReactComponent as PasswordIcon } from '../../assets/icons/Password.svg'
import { ReactComponent as ProfileDetailsIcon } from '../../assets/icons/ProfileDetails.svg'


const Profile = () => {
    const [userInfo, setUserInfo] = useState({});
    const [activeTab, setActiveTab] = useState('ProfileDetails');
    const { translate } = useTranslations()
    const [shortenedHref, setShortenedHref] = useState('')
    const alertBar = useAlert()
    const navigate = useNavigate()

    const shortHref = (href) => {
        if (!href || href === 'https://auth.myaic.eu') {
            return
        }
        let shortenedHref = href.replace('https://', '').replace('http://', '').split(".")

        if (shortenedHref[0] === 'dev' || shortenedHref[0] === 'auth') {
            shortenedHref = shortenedHref[1]
            setShortenedHref(shortenedHref)
            return
        }
        setShortenedHref(shortenedHref[0])

        return
    }

    useEffect(() => {
        axios.get('/user/info')
            .then(response => {
                setUserInfo(response.data)
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });

        shortHref(document.referrer)
    }, []);

    return (
        <div className="Profile">
            <div className="Profile_menu">
                <div className={`Profile_menu_item ${activeTab==='ProfileDetails'?'active_tab':''}`} onClick={()=>setActiveTab('ProfileDetails')}>
                    <ProfileDetailsIcon className="Profile_menu_item_icon"/>
                    <div>{translate('Profile details')}</div>
                </div>
                <div className={`Profile_menu_item ${activeTab==='Password'?'active_tab':''}`} onClick={()=>setActiveTab('Password')}>
                    <PasswordIcon className="Profile_menu_item_icon"/>
                    <div>{translate('Password')}</div>
                </div>
                <div className={`Profile_menu_item ${activeTab==='Application'?'active_tab':''}`} onClick={()=>setActiveTab('Application')}>
                    <ApplicationsIcon className="Profile_menu_item_icon"/>
                    <div>{translate('Applications')}</div>
                </div>
                <div className="Profile_menu_divider"/>
                {shortenedHref && <div className={`Profile_menu_item`} onClick={() => window.location.href = document.referrer}>
                    <div>{`${translate('Back to')} ${shortenedHref}`}</div>
                </div>}
                <div className={`Profile_menu_item`} onClick={()=> navigate('/logout')}>
                    <LogoutIcon className="Profile_menu_item_icon"/>
                    <div>{translate('Logout')}</div>
                </div>
            </div>

            <div className="Profile_tab_container">
                {activeTab==='ProfileDetails' && <ProfileDetails/>}
                {activeTab==='Password' &&   <Password email={userInfo.email}/>}
                {activeTab==='Application' && <Application/>}
            </div>

        </div>
    );
}

export default Profile;

