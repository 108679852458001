import React, { useState} from 'react';
import axios from "axios";
import { useAlert } from 'react-alert'
import './ProfileTab.css'
import qs from 'qs';
import { useTranslations } from "../../../utils/hooks/useTranslation";


function Password(props) {

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [currentPasswordError, setCurrentPasswordError] = useState();
    const [newPasswordError, setNewPasswordError] = useState();
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState();
    const alertBar = useAlert();

    const { translate } = useTranslations()

    const messages = {
        resetPassword: translate("Reset password request sent, logout and check email"),
        passChanged: translate("Password changed"),
    }

    const validate = () => {
        setCurrentPasswordError(false)
        setNewPasswordError(false)
        setConfirmNewPasswordError(false)

        if (!currentPassword) setCurrentPasswordError(true)
        if (!newPassword) setNewPasswordError(true)
        if (!confirmNewPassword) setConfirmNewPasswordError(true)
        return (currentPassword && currentPassword && confirmNewPassword);
    }

    const discardChanges = () => {
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
    }


    const resetPassword = () => {

        const config = {
            url: '/auth/reset_password_request',
            method: 'post',
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({'Email': props.email
            })
        };

        axios(config)
            .then(response => {
                alertBar.success(messages.resetPassword)
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    }


    const handleSubmit = event => {
        event.preventDefault();
        if (!validate()) return;

        const config = {
            url: '/user/change_password',
            method: 'post',
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({'CurrentPassword': currentPassword,
                    'NewPassword': newPassword,
                'ConfirmNewPassword': confirmNewPassword
            })
        };

        axios(config)
            .then(response => {
                alertBar.success(messages.passChanged)
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    };



    return (
        <div className="Password ProfileTab">
            <div className="ProfileTab_head inputColumn">
                <div className="ProfileTab_head_title">{translate('Password')}</div>
                <div className="ProfileTab_head_description">{translate('Change the AIC Application Hub password.')}</div>
            </div>
            
            <div className="ProfileTab_divider"/>

            <div className="Password_editSection">
                <form className="Login_form inputColumn" onSubmit={handleSubmit} noValidate>
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>{translate('Current password')}</label>
                        <input
                            type="password"
                            className="aic_input_input"
                            required
                            value={currentPassword}
                            onChange={e => setCurrentPassword(e.target.value)}
                            id="currentPassword"
                            autoComplete="off"
                        />
                        <div className={`aic_input_error_msg ${currentPasswordError?'show_error':''}`}>{translate("Enter username")}</div>
                    </div>
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>{translate('New password')}</label>
                        <input
                            type="password"
                            className="aic_input_input"
                            required
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)}
                            id="newPassword"
                            autoComplete="off"
                        />
                        <div className={`aic_input_error_msg ${newPasswordError?'show_error':''}`}>{translate("Enter password")}</div>
                    </div>
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>{translate('Confirm new password')}</label>
                        <input
                            type="password"
                            className="aic_input_input"
                            required
                            value={confirmNewPassword}
                            onChange={e => setConfirmNewPassword(e.target.value)}
                            id="confirmNewPassword"
                            autoComplete="off"
                        />
                        <div className={`aic_input_error_msg ${confirmNewPasswordError?'show_error':''}`}>{translate("Enter password")}</div>
                    </div>

                    <div className="Password_buttons">
                        <button
                            className="btn__hard"
                            type="submit">
                            {translate('Change password')}
                        </button>
                        <div className="btn__transparent_underline" onClick={discardChanges}>
                            {translate('Discard Changes')}
                        </div>
                    </div>
                </form>

                <div className="Password_forgotPassword_container">
                    <p className="Password_forgotPassword_title">
                        {translate('Forgot password?')}
                    </p>
                    <p className="Password_forgotPassword_text text__light">
                        {translate('If you can’t remember your current password, you can reset it. We will help you reset current password and set a new one.')}
                    </p>
                    <button className="Password_forgotPassword_btn btn__medium" onClick={resetPassword}>
                        {translate('Reset password')}
                    </button>
                </div>

            </div>
        </div>
    );
}

export default Password;

