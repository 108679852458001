import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import Navbar from "./components/Navbar/Navbar.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Login from "./pages/Login/Login";
import Logout from "./pages/Logout/Logout";
import Register from "./pages/Register/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Profile from "./pages/Profile/Profile";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import UserConfirmed from "./pages/RedirectsFromBackend/UserConfirmed";

function App() {

    return (
        <BrowserRouter>
            <Navbar/>
            <Routes>
                <Route path='login' element={<Login/>}/>
                <Route path='logout' element={<Logout/>}/>
                <Route path='register' element={<Register/>}/>
                <Route path='profile' element={<Profile/>}/>
                <Route path='forgot_password' element={<ForgotPassword/>}/>
                <Route path='reset_password/:token' element={<ResetPassword/>}/>
                <Route path='email_confirmed' element={<UserConfirmed/>}/>
                <Route path='*' element={<Navigate to='login'/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;

