import React, {useEffect, useState} from 'react';
import {validateFields, getDomainName} from "../../../utils/utils";
import { useTranslations } from "../../../utils/hooks/useTranslation";
import axios from "axios";
import {useAlert} from 'react-alert'
import './ProfileTab.css'
import DeleteAccountModal from "../../../components/DeleteAccountModal/DeleteAccountModal";
import Spinner from "../../../components/Spinner/Spinner";
import ProfileSection from "../../../components/ProfileSection/ProfileSection";


function ProfileDetails() {
    const alertBar = useAlert();
    const { translate } = useTranslations()
    const [profileFields, setProfileFields] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false);

    const closeModal = () => {
        setShowDeleteModal(false)
        setShowDeleteProfileModal(false)
    }


    const loadProfile = () => {
        axios.get('/user/profile/')
            .then(response => {
                setProfileFields(response.data)
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    }

    useEffect(() => {
        loadProfile()
    }, []);


    const handleChange = ( value, valueIndex, section_index ) => {
        let newData = {sections: []}
        newData.sections = profileFields.sections.map(( section, s_index ) => {
            if (s_index === section_index) {
                section.fields = section.fields.map(( field, f_index ) => {
                    if (f_index === valueIndex) {
                        return {...field, value: value}
                    } else {
                        return {...field}
                    }
                })
            }
            return section
        })
        setProfileFields(newData)
    }


    const validateProfile = () => {
        let result
        let invalidForm = false
        let newProfileFields = Object.assign({}, profileFields)

        newProfileFields.sections = newProfileFields.sections.map(section => {
            result = validateFields(section.fields, () => {}, () => {})
            if (!invalidForm && result.invalidForm) invalidForm = true;
            return {...section, fields: result.newArr}
        })

        setProfileFields(newProfileFields)
        if (invalidForm) {
            alertBar.error(translate("Failed to save - please fill required fields"))
        }
        return invalidForm
    }


    const handleSubmit = event => {
        event.preventDefault();
        if (validateProfile()) return

        const config = {
            url: '/user/edit',
            method: 'post',
            data: profileFields.sections
        };

        axios(config)
            .then(response => {
                alertBar.success(translate("Profile changed"))
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    };

    if (!profileFields?.sections) return <Spinner/>

    return (
        <div className="ProfileDetails ProfileTab">
            <form onSubmit={handleSubmit} noValidate>
                <div className="ProfileTab_head ProfileTab_head_double">
                    <div className="ProfileTab_head_left">
                        <div className="ProfileTab_head_title">{translate('Profile details')}</div>
                        <div
                            className="ProfileTab_head_description">{translate('Manage your data and preferences here.')}</div>
                    </div>
                    <div className="ProfileTab_head_right">
                        <div className="btn__transparent_underline" onClick={loadProfile}>
                            {translate('Discard Changes')}
                        </div>
                        <button
                            className="btn__hard"
                            type="submit">
                            {translate('Save changes')}
                        </button>
                    </div>

                </div>

                {profileFields?.sections?.map(( section, section_index ) => {
                    return <ProfileSection key={section_index} section={section} handleChange={handleChange} section_index={section_index}/>
                })}
            </form>

            <div className="ProfileTab_divider"/>

            <div className="ProfileTab_delete_profile">
                <div className="ProfileTab_head_title">{translate('Delete profile')}</div>
                <div className="Application_warning">
                    <div className="Application_warning_title">{translate("Warning")}!</div>
                    <div
                        className="Application_warning_description">{translate("If you delete your profile, you will permanently lose access to all your data in every application.")}</div>
                    <div
                        className="Application_warning_bottomline">{translate("This operation can’t be undone.")}</div>
                </div>
                <button
                    className="btn__hard"
                    onClick={() => setShowDeleteProfileModal(true)}
                    type="submit">
                    {translate('Delete profile')}
                </button>
            </div>

            {showDeleteProfileModal && <DeleteAccountModal closeDeleteAccountModal={closeModal} domain={getDomainName()}
                                                           mode={'deleteProfile'}/>}
            {showDeleteModal &&
                <DeleteAccountModal closeDeleteAccountModal={closeModal} domain={getDomainName()} mode={'delete'}/>}

        </div>)
}

export default ProfileDetails;

