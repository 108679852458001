import React  from 'react';
import './Footer.css'


const Footer = () => {

    return (
        <footer className='footer'>
            <div className='footer__container'>
                    <p className='footer__bottom_privacy_copyright'>Copyright Ⓒ 2022-2023 AIC Europe B.V. All Rights Reserved</p>
            </div>
        </footer>
    )
}

export default Footer;
