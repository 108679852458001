import React, { useState } from 'react'
import './DeleteAccountModal.css'
import {useTranslations} from "../../utils/hooks/useTranslation";

// svg
import { ReactComponent as CloseBlack } from '../../assets/icons/CloseBlack.svg'
import axios from "axios";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";


function DeleteAccountModal(props) {
    const { translate } = useTranslations()
    const alertBar = useAlert();

    const [confirmationText, setConfirmationText] = useState('');
    const closeDeleteAccountModal = props.closeDeleteAccountModal
    const domain = props.domain
    const mode = props.mode

    const navigate = useNavigate()



    const configUnsign = {
        url: '/user/unsign/' + domain,
        method: 'delete',
    };

    const configDeleteProfile = {
        url: '/user/delete_account',
        method: 'delete',
    };


    const deleteAccount = () => {

        axios(mode==='deleteProfile'?configDeleteProfile:configUnsign)
            .then(response => {
                alertBar.success(translate("Account deleted"))
                navigate('/logout')
            })
            .catch(error => {
                console.warn(error)
                alertBar.error(error)
            });
    }




    return (
        <div onClick={() => closeDeleteAccountModal()} className='DeleteAccountModal'>
            <div
                onClick={e => e.stopPropagation()}
                className='DeleteAccountModal_container'>

                <CloseBlack onClick={closeDeleteAccountModal} className='DeleteAccountModal__closeSVG' />

                <div className='DeleteAccountModal_content'>
                    <div className="Application_warning">
                        <div className="Application_warning_title">{translate("Warning")}!</div>
                        <div className="Application_warning_description">{translate("If you delete your account in a given application, you will permanently lose access to all your data in that application.")}</div>
                        <div className="Application_warning_bottomline">{translate("This operation can’t be undone.")}</div>
                    </div>
                    <div className='DeleteAccountModal__topPart'>
                        {mode==='deleteProfile' && <p className='DeleteAccountModal__text'>{`${translate('You are going to delete your profile and all data related to all signed applications')}`}</p>}
                        {mode==='delete' && <p className='DeleteAccountModal__text'>{`${translate('You are going to delete your account and all data related to the')} ${domain} ${translate('application')}`}</p>}
                    </div>

                    <div className='DeleteAccountModal__warnPart'>
                        {translate('This action can’t be undone.')}
                    </div>

                    <div className='DeleteAccountModal__middlePart'>
                        <div className="aic_input">
                            <label
                                className='aic_input_label text__medium'>
                                {translate('Type DELETE to confirm')}
                            </label>
                            <input
                                type="text"
                                className={`aic_input_input`}
                                value={confirmationText || ''}
                                onChange={e => setConfirmationText(e.target.value)}
                                autoComplete="off"
                            />
                        </div>
                    </div>


                    <div className='DeleteAccountModal__btnContainer'>
                        <button
                            className="btn__hard"
                            disabled={confirmationText.toUpperCase() !== 'DELETE'}
                            onClick={deleteAccount}
                            type="submit">
                            {translate('Delete')} {domain} {translate('account')}
                        </button>
                        <button className='btn__light' onClick={closeDeleteAccountModal}>{translate('Cancel')}</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DeleteAccountModal
