import React, {useState} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import './ForgotPassword.css'
import { ReactComponent as ArrowLeft } from '../../assets/svg/ArrowLeft.svg'
import qs from "qs";
import {useTranslations} from "../../utils/hooks/useTranslation";


function ForgotPassword() {
    const [email, setemail] = useState("");
    const [success, setSuccess] = useState(false)
    const navigate = useNavigate();

    const { translate } = useTranslations()

    const handleSubmit = event => {
        event.preventDefault();

        const config = {
            data: qs.stringify({'Email': email}),
            headers: {
                "Content-Type": 'application/x-www-form-urlencoded',
            },
            url: '/auth/reset_password_request',
            method: 'post'
        };

        axios(config)
            .then(response => {
                setSuccess(true)
            })
            .catch(error => {
                console.warn(error)
            });

    };

    const renderForm = () => {
        return (<div className="ForgotPassword_container">
                    <div className="ForgotPassword_back_btn" onClick={() => navigate('/login')}>
                        <ArrowLeft />
                        <div>{translate("Back")}</div>
                    </div>
                    <h2 className="ForgotPassword_title">{translate("Forgot Password")}?</h2>
                    <div className="ForgotPassword_description">
                        {translate("If you forgot your password you can set up a new one.")}
                    </div>
                    <div className="ForgotPassword_description">
                        {translate("Enter your registration email and we will send you a link. Use it to set up your password.")}
                    </div>

                    <form className="Login_form" onSubmit={handleSubmit} noValidate>
                        <div className="aic_input">
                            <label className='aic_input_label text__medium'>{translate('Email')}</label>
                            <input
                                type="text"
                                className="aic_input_input"
                                required
                                value={email}
                                placeholder={translate("Type your email")}
                                onChange={e => setemail(e.target.value)}
                                id="email"
                                autoComplete="off"
                            />
                        </div>

                        <button
                            className="btn__hard"
                            type="submit">
                            {translate("Reset password")}
                        </button>
                    </form>
                </div>)
    }


    const renderSuccess = () => {
        return (
            <div className="ForgotPassword_container">
                <h2 className="ForgotPassword_title">{translate('Success')}</h2>
                <div className="Register_success">
                    {translate('Your reset password email has been sent. Please check it and follow the instructions inside.')}
                </div>
            </div>
        )
    }

    return (
       <div className="ForgotPassword page">
           {success && renderSuccess()}
           {!success && renderForm()}

       </div>
   )
}

export default ForgotPassword;

