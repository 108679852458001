import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css'
import Logo from '../../assets/svg/logo.jsx'
import axios from "axios";


function Navbar() {

    const [userInfo, setUserInfo] = useState({});
    const location = useLocation();

    useEffect(() => {
        axios.get('/user/info')
            .then(response => {
                setUserInfo(response.data)
            })
            .catch(error => {
                console.warn(error)
            });
    }, [location]);

    return (


        <header className='nav'>
            <nav className='nav__container'>
                <Link to='home' className='nav__header'>
                    <div className="nav__headerImg">
                        <Logo/>
                    </div>
                </Link>


                {userInfo.email && location.pathname === '/profile' &&
                    <div className="nav_userInfo">
                        <div className="nav_userInfo_text">
                            <div className="nav_userInfo_name">{userInfo.first_name} {userInfo.last_name}</div>
                            <div className="nav_userInfo_email">{userInfo.email}</div>
                        </div>
                        <div className="nav_userInfo_circle">
                            {userInfo.first_name && userInfo.first_name[0]} {userInfo.last_name && userInfo.last_name[0]}
                        </div>
                    </div>
                }
            </nav>
        </header>
    )
}

export default Navbar