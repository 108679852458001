import {getDomainName} from "../../utils/utils";
import { useNavigate } from 'react-router-dom';

import './UserConfirmed.css'
import { useTranslations } from "../../utils/hooks/useTranslation";

function UserConfirmed() {
    const domainName = getDomainName()
    const navigate = useNavigate()

    const { translate } = useTranslations()

    return (
        <div className="UserConfirmed page">
            <div className="UserConfirmed_container">
                    <h2 className="UserConfirmed_welcome_title">{`${translate("Success")}`}</h2>
                    <div className="UserConfirmed_success">{`${translate('Your')}  ${domainName} ${translate('account is activated')}`}</div>
                    <div className="UserConfirmed_success">{translate('Now you can ')}
                        <button
                            data-testid="logIn"
                            className="btn__light"
                            onClick={()=> navigate('/login')}
                        >{translate('log in')}</button>
                    </div>
            </div>
        </div>
    )
}

export default UserConfirmed;

