import React from 'react';
import axios from 'axios';
import {  useNavigate } from 'react-router-dom';
import {useAlert} from "react-alert";


function Logout() {
    const navigate = useNavigate()
    const alertBar = useAlert()

    const config = {
        url: '/auth/logout',
        method: 'delete'
    };

    axios(config)
        .then(response => {
            navigate('/login', {state:response.data})
        })
        .catch(error => {
            console.warn(error)
            alertBar(error)
        });
    return null
}

export default Logout;
