import React from "react"
import {useTranslations} from "../../utils/hooks/useTranslation";
import {FormField} from "../../utils/FormField";

const ProfileSection = ({ section, handleChange, section_index }) => {
const { translate } = useTranslations()
    return (
        <div className="ProfileDetails_section">
            <div className="ProfileTab_divider"/>
            <div className="ProfileDetails_section_title">{translate(section.title)}</div>
            <div className="ProfileDetails_section_fields inputColumn">
                {<FormField fields={section.fields} handleChange={handleChange}
                            emailError={false} passError={false} section_index={section_index}/>}
            </div>
        </div>
    )
}

export default ProfileSection;
