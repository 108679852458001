import { transitions, positions, AlertComponentPropsWithStyle } from 'react-alert'
import './ReactAlert.css'

// icons
import { ReactComponent as CloseWhite } from '../../assets/icons/CloseWhite.svg'
import { ReactComponent as CloseBlack } from '../../assets/icons/CloseBlack.svg'
import { ReactComponent as CheckMark } from '../../assets/icons/CheckMark.svg'
import { ReactComponent as Warning } from '../../assets/icons/Warning.svg'
import { ReactComponent as Error } from '../../assets/icons/Error.svg'

export const alertOptions = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    offset: '0.2rem',
    transition: transitions.SCALE
}

export const AlertTemplate = ({ style = {}, options = { type: '' }, message = '', close = () => { } }) => {

    const customStyle = () => {
        if (options.type === 'info') return { ...style, backgroundColor: "#FFD200", color: "#32343A" }
        if (options.type === 'success') return { ...style, backgroundColor: "#0EAE4A", color: "#FFFFFF" }
        if (options.type === 'error') return { ...style, backgroundColor: "#EA280A", color: "#FFFFFF" }
        return { ...style, backgroundColor: "#61636B", color: "#FFFFFF" }
    }


    const parseMsg = (obj) => {
        let msg = 'Missing connection';
        if (obj) {
            if (typeof obj === 'string' || obj instanceof String) {
                msg = obj;
            } else {
                msg = 'Usnupported error';
            }
            if (obj.message) {
                msg = obj.message;
            }
            if (obj.response) {
                if (obj.response.message) {
                    msg = obj.response.message;
                }
                if (obj.response.data) {
                    if (
                        typeof obj.response.data === 'string' ||
                        obj.response.data instanceof String
                    ) {
                        msg = obj.response.data;
                    }
                    if (obj.response.data.message) {
                        msg = obj.response.data.message;
                    }
                    if (obj.response.data.localized_message) {
                        msg = obj.response.data.localized_message.description;
                    }
                }
            }
        }
        return msg;
    }


    return (
        <div style={customStyle()} className='reactAlert'>
            <div className='reactAlert__container'>
                {options.type === 'info' && <Warning className='reactAlert__svg' />}
                {options.type === 'success' && <CheckMark className='reactAlert__svg' />}
                {options.type === 'error' && <Error className='reactAlert__svg' />}
                <span className='reactAlert__text'>{parseMsg(message)}</span>
            </div>
            <button className='reactAlert__btn' onClick={close}>{options.type === 'info' ? <CloseBlack /> : <CloseWhite />}</button>
        </div>
    )
}