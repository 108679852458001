import React from 'react'
import './Spinner.css'


const Spinner = () => {
    return (
        <div className="spinner_ring"><div></div><div></div><div></div><div></div></div>
    )
}

export default Spinner