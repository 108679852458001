import { useEffect, useState, useCallback, useMemo } from "react"
import axios from "axios";

const initLang = navigator.language

export const useTranslations = (lang = initLang) => {
    const [translations, setTranslations] = useState(JSON.parse(localStorage.getItem('translations')) || {});

    const getTranslations = useCallback(() => {
        axios
            .get('/help/translate/', {
                headers: {
                    'accept-language': lang,
                    iUserCloudForceLanguage: lang
                }
            })
            .then(res => {
                setTranslations({...translations, [lang]:{...res.data}})
            })
            .catch(error => console.error(error))
    }, [translations]);

    useEffect(() => {
        if (Object.keys(translations).includes(lang)) return;

        getTranslations();
    }, []);

    useEffect(() => {
        localStorage.setItem('translations', JSON.stringify(translations))
    }, [translations])

    const translation = useMemo(() => translations[lang], [translations, lang])

    const translate = useCallback((t) => {
        if (!t) return;

        if (translation && translation[t]) {
            return translation[t];
        } else {
            let uppercased = t.charAt(0).toUpperCase() + t.slice(1);
            return uppercased.split('_').join(' ');
        }
    }, [translation]);


    return { translate };
}
