import React, {useEffect, useState} from 'react';
import {useTranslations} from "../../../utils/hooks/useTranslation";
import axios from "axios";
import { useAlert } from 'react-alert'
import './ProfileTab.css'
import DeleteAccountModal from "../../../components/DeleteAccountModal/DeleteAccountModal";

function Application() {

    const alertBar = useAlert()
    const { translate } = useTranslations()
    const [applications, setApplications] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);


    useEffect(() => {
            axios.get('/user/domains')
                .then(response => {
                    // remove suffix to the first dot from domain name
                    const applications = response.data
                        .map(appName => ({
                            ...appName,
                            domain: appName.domain = appName.domain.substring(appName.domain.indexOf(".") + 1)
                        }))
                    setApplications(applications)
                })
                .catch(error => {
                    console.warn(error)
                    alertBar.error(error)
                });
    }, []);

    const deleteAccount = () => {
        setShowDeleteModal(true)
    };

    const closeModal = () => {
        setShowDeleteModal(false)
    }

    return (
        <div className="Application ProfileTab">
            <div className="ProfileTab_head inputColumn">
                <div className="ProfileTab_head_title">{translate('Application')}</div>
                <div className="ProfileTab_head_description">{translate('Manage the applications connected to your account in the AIC Application Hub.')}</div>
            </div>

            <div className="ProfileTab_divider"/>
            <div className="Application_app_tiles_container">
                {applications.map( (application, index) => {
                    return (
                        <div key={application.domain + index} className="Application_app_tile">
                            <div className="Application_app_icon_title">
                                <div className="Application_app_tile_icon">
                                        <img src={require('../../../assets/icons/thermesis.png')} alt="Application_app_img"/>
                                </div>
                                <div className="Application_app_tile_title">{translate(application.domain)}</div>
                            </div>
                                <div className="Application_app_tile_description">{translate('Boiler management, ordering spare parts and technical specification platform')}</div>
                                <button className="Application_app_tile_button btn__delete_soft" onClick={() => deleteAccount(application.domain)}>
                                    {translate("Delete account and all data")}
                                </button>
                                {showDeleteModal && <DeleteAccountModal closeDeleteAccountModal={closeModal} domain={application.domain} mode={'delete'}/>}
                           </div>
                    )
                })}
            </div>

        </div>
    );
}

export default Application;

