import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {validateFields, getDomainName} from "../../utils/utils";
import './Register.css'
import Select from 'react-select';
import {useLocation} from 'react-router-dom';
import { useAlert } from 'react-alert'
import {FormField} from "../../utils/FormField";
import { useTranslations } from "../../utils/hooks/useTranslation";

const selectStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', borderRadius: '0px' }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' })
}

function Register() {
    const [registerData, setRegisterData] = useState();
    const [countries, setCountries] = useState([])
    const [country, setCountry] = useState()
    const [passError, setPassError] = useState(false)
    const [emailError, setEmailError] = useState()
    const [registered, setRegistered] = useState(false)
    const [isProgressiveRegister, setIsProgressiveRegister] = useState(false)
    const domainName = getDomainName()

    const location = useLocation();
    const alertBar = useAlert()

    const { translate } = useTranslations()

    useEffect(() => {
        axios.get('/help/countries')
            .then(response => {
                setCountries(response.data)
            })
            .catch(error => {
                console.warn(error)
            })
    }, []);


    //if you need to extend account to other application
    useEffect( () => {
        if (location.state && Object.values(location.state?.fields).length > 0){
            setRegisterData(Object(location.state.fields))
            setIsProgressiveRegister(true)
        }
    }, [location.state])


    useEffect(() => {
        if (country){
            let copyRegisterData = {}
            registerData?.forEach(field => {
                return copyRegisterData[field.name] = field.value
            })

            axios.get(`/help/register_fields/${country.value}/${domainName}`)
                .then(response => {
                    response.data.fields.forEach(field => {
                        if (field.name !== 'country')
                            field.value = copyRegisterData[field.name] || field.value
                    })
                    setRegisterData(Object(response.data.fields))
                })
                .catch(error => {
                    console.warn(error)
                    alertBar.error(error)
                });
        }
    }, [country]);



    const handleSubmit = event => {
        event.preventDefault();
        if(validateRegister(registerData)) return
        let data = {}
        if (isProgressiveRegister) data = {username: location.state.username}

        registerData.forEach((field) => {
            if (field.type === 'select'){
                data[field.name] = field.value?.value;
            }
            else data[field.name] = field.value
        })


        const config = {
            data: data,
            url: isProgressiveRegister?'/auth/fill_user_data/'+domainName:'/auth/register/' +domainName,
            method: 'post'
        };

        axios(config)
            .then(response => {
                setRegistered(true)
            })
            .catch(error => {
                console.dir(alertBar)
                console.warn(error)
                alertBar.error(error)

            });
    };

    const validateRegister = data => {
        setPassError(false)
        setEmailError(false)
        let result = validateFields(data, setEmailError, setPassError)
        setRegisterData(result.newArr)
        if (result.invalidForm){
            alertBar.error(translate("Failed to register - please fill required fields"))
        }

        return result.invalidForm
    }


    const handleChange = (value, valueIndex) => {
        let countryChanged = false
        let newData = registerData.map( (field, index) => {
            if(index === valueIndex){
                if (field.name === 'country'){
                    countryChanged = true
                    setCountry(value)
                }
                return {...field, value: value}
            }else{
                return {...field}
            }
        })
        if(!countryChanged) setRegisterData(newData)
    }


    const RenderRegisterForm = () => {
        return ( <div className="Register_container">

            {!isProgressiveRegister &&
                <h2 className="Register_welcome_title">{translate("Create")} {domainName} {translate("account")}</h2>
            }
            {isProgressiveRegister &&
                <h2 className="Register_welcome_title">{translate("Complete registration for")} {domainName} {translate("account")}</h2>
            }
                    {!registerData &&
                    <div className="aic_input">
                        <label className='aic_input_label text__medium'>{translate("Choose your country")}</label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable={true}
                            name="country"
                            styles={selectStyles}
                            options={countries}
                            placeholder={translate("-Choose-")}
                            value={country}
                            onChange={(item) => setCountry(item)}
                        />
                    </div>
                    }
                    <form onSubmit={handleSubmit} noValidate>
                        {<FormField fields={registerData} handleChange={handleChange} emailError={emailError} passError={passError} section_index={0}/>}
                        <div className="Register_buttons">
                            {registerData && <button
                                className="btn__hard"
                                type="submit">
                                {translate('Create account')}
                            </button>}
                        </div>
                    </form>
                </div>)
    }

    const RenderSuccess = () => {
        return (<div className="Register_container">
                <h2 className="Register_welcome_title">{translate("Success")}</h2>
                <div className="Register_success">{translate('You have just created')} {domainName} {translate('account')}</div>

                <div className="Register_success">{translate('In a moment you will receive an email with an activation link.')}</div>
                <div className="Register_success">{translate('You may close this window.')}</div>
            </div>
        )
    }

    return (
        <div className="Register page">
            {!registered && RenderRegisterForm()}
            {registered && RenderSuccess()}
        </div>
    );
}

export default Register;

