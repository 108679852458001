import React from 'react';

const Logo = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 0H0V48H48V0Z" fill="#FF4F00"/>
            <path d="M15.3399 38.4292L14.3828 38.428L14.3917 30.1914L15.3494 30.1925L15.3399 38.4292Z" fill="white"/>
            <path d="M16.5469 35.1534V33.4442C16.5454 32.0507 16.7123 31.404 17.2087 30.8812C17.7196 30.3431 18.3123 30.1698 19.648 30.1685L19.8365 30.168C21.2694 30.168 21.924 30.4512 22.4311 31.2909L22.5272 31.4536L22.4769 31.4854L21.7436 31.9176L21.6994 31.8592C21.3078 31.3405 20.9151 31.1903 19.9483 31.1903L19.764 31.1907C18.8243 31.1916 18.4023 31.2989 18.0911 31.6158C17.7976 31.9151 17.7063 32.2042 17.708 33.4294V35.1682C17.7063 36.3932 17.7976 36.6825 18.0911 36.9816C18.4023 37.2987 18.8243 37.4058 19.764 37.4067L19.9483 37.4071C20.9151 37.4071 21.3078 37.2571 21.6994 36.7382L21.7436 36.6798L22.4769 37.112L22.5272 37.1438L22.4311 37.3065C21.924 38.1462 21.2694 38.4296 19.8365 38.4296L19.648 38.4289C18.3123 38.4276 17.7196 38.2545 17.2087 37.7162C16.7123 37.1934 16.5454 36.5469 16.5469 35.1534Z" fill="white"/>
            <path d="M10.1138 34.2867C9.15052 34.2867 8.73234 34.4056 8.39325 34.7767C8.14125 35.0592 8.01307 35.4458 8.01216 35.9247C8.01198 36.4085 8.13907 36.7891 8.38998 37.0563C8.7398 37.4392 9.1278 37.55 10.1205 37.5514C11.1178 37.5514 11.5243 37.4365 11.8694 37.0609C12.146 36.7391 12.2325 36.4921 12.2336 35.5774L12.2349 34.2892L10.1138 34.2867ZM10.1014 38.448H10.0194C7.97925 38.4461 7.0298 37.6438 7.03125 35.9234C7.03198 35.0914 7.26852 34.4602 7.73398 34.0467C8.23743 33.5622 8.86289 33.3898 10.1222 33.3898L12.2358 33.392C12.2363 32.1145 12.1433 31.8278 11.8274 31.5112C11.4958 31.1785 11.0462 31.066 10.0445 31.0649H9.98252C8.93652 31.0649 8.51798 31.222 8.11707 31.7651L8.07034 31.8281L7.28871 31.3749L7.33398 31.2987C7.83543 30.4532 8.5038 30.168 9.98234 30.168H10.0449C11.4118 30.1694 12.0183 30.344 12.5407 30.8856C13.0478 31.4112 13.2185 32.0618 13.2167 33.4641L13.2113 38.448L12.2947 38.4467L12.2563 37.8105C11.7398 38.3023 11.2276 38.448 10.1014 38.448Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.3303 29.6865C30.8776 29.6865 32.9458 31.7545 32.9458 34.3018C32.9458 36.8491 30.8776 38.9172 28.3303 38.9172C25.783 38.9172 23.7148 36.8491 23.7148 34.3018C23.7148 31.7545 25.783 29.6865 28.3303 29.6865ZM28.3325 32.8027H28.3267C28.1739 32.8036 28.0216 32.8618 27.9045 32.9772L26.1914 34.6905L26.1887 34.6932C25.9523 34.9296 25.9523 35.3134 26.1887 35.5498C26.425 35.7862 26.8088 35.7862 27.0452 35.5498L28.3303 34.2647L29.6154 35.5498C29.8518 35.7862 30.2356 35.7862 30.4719 35.5498C30.7085 35.3134 30.7085 34.9296 30.4719 34.6932L28.7588 32.98C28.641 32.8623 28.4868 32.8032 28.3325 32.8027Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M32.7261 31.7291C32.6696 31.634 32.6778 31.514 32.7467 31.4274C33.5925 30.3667 34.8958 29.6865 36.3567 29.6865C38.9039 29.6865 40.9721 31.7545 40.9721 34.3018C40.9721 36.8491 38.9039 38.9172 36.3567 38.9172C34.8943 38.9172 33.5899 38.2356 32.7439 37.1731C32.6767 37.0883 32.6689 36.9707 32.7241 36.8778C33.1689 36.122 33.4239 35.2414 33.4239 34.3018C33.4239 33.3636 33.1696 32.4842 32.7261 31.7291ZM35.7516 33.1229C35.7512 33.1143 35.751 33.1056 35.751 33.0969C35.751 32.7625 36.0225 32.4912 36.3567 32.4912C36.691 32.4912 36.9625 32.7625 36.9625 33.0969C36.9625 33.1163 36.9616 33.1354 36.9598 33.1543V34.2509C36.9616 34.2698 36.9625 34.2889 36.9625 34.3083C36.9625 34.3278 36.9616 34.3469 36.9598 34.3658V35.4622C36.9616 35.4812 36.9625 35.5003 36.9625 35.5198C36.9625 35.854 36.691 36.1256 36.3567 36.1256C36.0225 36.1256 35.751 35.854 35.751 35.5198C35.751 35.5111 35.7512 35.5023 35.7516 35.4938V34.3343C35.7512 34.3256 35.751 34.3171 35.751 34.3083C35.751 34.2996 35.7512 34.2909 35.7516 34.2823V33.1229Z" fill="white"/>
        </svg>

    );
};

export default Logo;
